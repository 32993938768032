// => LOGIN
const LOGIN = '/auth/login';

// => LOGOUT
const LOGOUT = '';

export const authEndpoints = {
    LOGIN,
    LOGOUT,
};
