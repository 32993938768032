import React, { useEffect, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Avatar, Box, Button, Chip, Grid, IconButton } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { Add, EditTwoTone } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { clientsSelector } from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import {
  useGetAllClientsQuery,
  useLazyGetAllClientsQuery,
} from "../../api/clients/clientsAPI";
import { setClients } from "../../state/features/clients/clientsSlice";
import CreateClient from "./CRUD/CreateClient";
import UpdateClient from "./CRUD/UpdateClient";
import ActivateDeactivateClient from "./CRUD/ActivateDeactivateClient";
import DeleteClient from "./CRUD/DeleteClient";
import ClientWidget from "../../components/widgets/ClientWidget";
import DataTable from "react-data-table-component";
import CustomCard2 from "../../components/cards/CustomCard2";
import CountBadge from "../../components/CountBadge";

const Clients = () => {
  // ############# Const ##############
  const RTK = useRTK();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("");
  const [clientDetails, setClientDetails] = useState({});

  // ############# Comp State ############
  const [dialogOpen, setDialogOpen] = useState(false);

  // ############ Redux state #################
  const { clients } = useSelector(clientsSelector);

  // ############# FUNC ###############
  const handleDialogyOpen = (type) => {
    setActionType(type);
    setDialogOpen(true);
  };
  const handleDialogyClose = () => {
    setDialogOpen(false);
  };

  // ############# RTK ##################
  const [getClients, { isLoading, isSuccess, data: responseData }] =
    useLazyGetAllClientsQuery();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      // getClients();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setClients(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  const tableColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tease image",
      width: "200px",
      selector: (row) => (
        <>
          <Avatar
            src={row?.image}
            variant="square"
            sx={{
              height: 60,
              width: 90,
              img: {
                objectFit: "cover",
              },
              my: 1,
              border: `1px dotted ${colors.secondary}`,
              borderRadius: 2,
            }}
          />
        </>
      ),
    },
    {
      name: "Client name",
      selector: (row) => <p>{row?.name}</p>,
    },
    {
      name: "Year",
      selector: (row) => <p>{row?.year}</p>,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Chip
            label={row.status ? "Active" : "In-Active"}
            sx={{ mr: 1 }}
            size="small"
            color={row.status ? "success" : "warning"}
          />
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Box>
            <ActivateDeactivateClient status={row.status} id={row.id} />
            <IconButton
              size="small"
              sx={{
                p: 1,
                mx: 2,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
              }}
            >
              <EditTwoTone
                color="warning"
                sx={{ fontSize: 20 }}
                onClick={() => {
                  let type = "update";
                  setClientDetails(row);
                  handleDialogyOpen(type);
                }}
              />
            </IconButton>
            <DeleteClient client={row} />
          </Box>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      {/* ########### Dialog ############## */}
      <CustomDialog
        head={actionType === "create" ? "Create new client" : "Update client"}
        openDialog={dialogOpen}
        closeDialog={handleDialogyClose}
      >
        {actionType === "create" ? (
          <CreateClient handleClose={handleDialogyClose} />
        ) : (
          <UpdateClient
            client={clientDetails}
            handleClose={handleDialogyClose}
          />
        )}
      </CustomDialog>

      <PageLayout
        pageTitle={"Clients managements"}
        isLoading={isLoading}
        action={
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<Add />}
            onClick={() => {
              let type = "create";
              handleDialogyOpen(type);
            }}
            sx={{
              py: { md: 1, xs: 1 },
              px: 2,
              fontWeight: "bold",
              fontSize: 12,
              mr: 4,
              borderRadius: `20px !important`,
            }}
          >
            Add new client
          </Button>
        }
      >
        {isEmpty(clients) ? (
          <>
            <NoContent message={"No client added"} />
          </>
        ) : (
          <Grid container>
            <Grid item sm={12}>
              <CustomCard2
                title={"All clients"}
                action={<CountBadge total={size(clients)} item={"Clients"} />}
              >
                <DataTable
                  columns={tableColumns}
                  data={clients}
                  rowKey={(record) => record?.id}
                  pagination={{
                    total: size(clients),
                    current: 1,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    defaultPageSize: 5,
                    defaultCurrent: 1,
                  }}
                />
              </CustomCard2>
            </Grid>
          </Grid>
        )}
      </PageLayout>
    </>
  );
};

export default Clients;
