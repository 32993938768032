import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  eventPortifolios: [],
  eventPortifolioDetails: null,
};

// !########## SLICE #############
const eventPortifoliosSlice = createSlice({
  name: "eventPortifoliosSlice",
  initialState,
  reducers: {
    setEventPortifolios: (state, actions) => {
      state.eventPortifolios = actions.payload;
    },
    setEventPortifolioDetails: (state, actions) => {
      state.eventPortifolioDetails = actions.payload;
    },
  },
});

export const { setEventPortifolios, setEventPortifolioDetails } =
  eventPortifoliosSlice.actions;
export default eventPortifoliosSlice.reducer;
