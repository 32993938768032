import React from "react";
import { Box, Grid } from "@mui/material";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/colors";

const PageLoader = () => {
  return (
    <>
      <Grid container>
        <Grid
          item
          sm={12}
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ ml: 20 }}>
            <Loader
              type="bubble-ping"
              bgColor={colors.secondary}
              color={colors.secondary}
              size={100}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PageLoader;
