import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { eventPortifoliosEndpoints } from "./eventPortifoliosEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const eventPortifoliosAPI = createApi({
  reducerPath: "eventPortifoliosAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
  tagTypes: ["EventPortifolios"],
  endpoints: (builder) => ({
    getAllEventPortifolios: builder.query({
      query: () => `${eventPortifoliosEndpoints.GET_ALL}`,
      providesTags: ["EventPortifolios"],
    }),
    getSingleEventPortifolio: builder.query({
      query: (payload) => `${eventPortifoliosEndpoints.GET_BY_ID}/${payload}`,
    }),
    createEventPortifolio: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.CREATE}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    updateEventPortifolio: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.UPDATE}/${payload.get("event_id")}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    activateEventPortifolio: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.ACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    deactivateEventPortifolio: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.DEACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    deleteEventPortifolio: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.DELETE}/${payload}`,
        method: `DELETE`,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),

    // GALLARY
    createEventGallary: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.CREATE_EVENT_GALLARY}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    updateEventGallary: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.UPDATE_EVENT_GALLARY}/${payload.get(
          "gallary_id"
        )}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
    deleteEventGallary: builder.mutation({
      query: (payload) => ({
        url: `${eventPortifoliosEndpoints.DELETE_EVENT_GALLARY}/${payload}`,
        method: `DELETE`,
      }),
      invalidatesTags: ["EventPortifolios"],
    }),
  }),
});

export const {
  useGetAllEventPortifoliosQuery,
  useGetSingleEventPortifolioQuery,
  useCreateEventPortifolioMutation,
  useUpdateEventPortifolioMutation,
  useActivateEventPortifolioMutation,
  useDeactivateEventPortifolioMutation,
  useDeleteEventPortifolioMutation,
  useCreateEventGallaryMutation,
  useUpdateEventGallaryMutation,
  useDeleteEventGallaryMutation,

  // Lazy
  useLazyGetAllEventPortifoliosQuery,
} = eventPortifoliosAPI;
