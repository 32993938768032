import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TopBar from "../../components/TopBar";
import SideBar from "../../components/SideBar";
import HotToastfy from "../../components/HotToastfy";
import useRTK from "../../hooks/useRTK";
import FullAppLoader from "../../components/FullAppLoader";
import { colors } from "../../assets/utils/colors";

const DrawerHeader = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const AppLayout = ({ children }) => {
  // ################ RTK ##################
  const RTK = useRTK();
  const getMainData = RTK.useRTKMainData();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      {getMainData ? (
        <>
          <FullAppLoader />
        </>
      ) : (
        <>
          <HotToastfy />

          {/* ############### sidebar ###############*/}
          <SideBar mobileOpen={mobileOpen} />

          {/* ############### content ###############*/}
          <Box
            className="main"
            sx={{
              flex: 1,
              display: "flex",
              position: "relative",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            {/* ############### topbar ############### */}
            <TopBar
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
            />

            {/* ############### Main content ###############*/}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              {children}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AppLayout;
