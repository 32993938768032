import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseURL } from '../baseUrl/baseURL';
import { eventTypesEndpoints } from './eventTypesEndpoints';
import { headers } from '../baseUrl/setHeaders';

export const eventTypesAPI = createApi({
    reducerPath: 'eventTypesAPI',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ['EventTypes'],
    endpoints: (builder) => ({
        getAllEventTypes: builder.query({
            query: () => `${eventTypesEndpoints.GET_ALL}`,
            providesTags: ['EventTypes'],
        }),
        getSingleEventTypes: builder.query({
            query: (payload) => `${eventTypesEndpoints.GET_BY_ID}/${payload}`,
        }),
        createEventTypes: builder.mutation({
            query: (payload) => ({
                url: `${eventTypesEndpoints.CREATE}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['EventTypes'],
        }),
        updateEventTypes: builder.mutation({
            query: (payload) => ({
                url: `${eventTypesEndpoints.UPDATE}/${payload.get(
                    'event_type_id',
                )}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ['EventTypes'],
        }),
        activateEventTypes: builder.mutation({
            query: (payload) => ({
                url: `${eventTypesEndpoints.ACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ['EventTypes'],
        }),
        deactivateEventTypes: builder.mutation({
            query: (payload) => ({
                url: `${eventTypesEndpoints.DEACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ['EventTypes'],
        }),
        deleteEventTypes: builder.mutation({
            query: (payload) => ({
                url: `${eventTypesEndpoints.DELETE}/${payload}`,
                method: `DELETE`,
            }),
            invalidatesTags: ['EventTypes'],
        }),
    }),
});

export const {
  useGetAllEventTypesQuery,
  useGetSingleEventTypesQuery,
  useCreateEventTypesMutation,
  useUpdateEventTypesMutation,
  useActivateEventTypesMutation,
  useDeactivateEventTypesMutation,
  useDeleteEventTypesMutation,

  // Lazy
  useLazyGetAllEventTypesQuery,
} = eventTypesAPI;
