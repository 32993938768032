import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    partiners: [],
};

// !########## SLICE #############
const partinersSlice = createSlice({
    name: 'partinersSlice',
    initialState,
    reducers: {
        setPartiners: (state, actions) => {
            state.partiners = actions.payload;
        },
    },
});

export const { setPartiners } = partinersSlice.actions;
export default partinersSlice.reducer;
