// => Auth selector
export const authSelector = (state) => state.authReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;

// => Packages selector
export const gallariesSelector = (state) => state.gallariesReducer;

// => clients selector
export const clientsSelector = (state) => state.clientsReducer;

// => eventTypes selector
export const eventTypesSelector = (state) => state.eventTypesReducer;

// => partiners selector
export const partinersSelector = (state) => state.partinersReducer;

// => event portifolios selector
export const eventPortifoliosSelector = (state) =>
  state.eventPortifoliosReducer;
