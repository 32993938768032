import { Button, CircularProgress, Switch, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import {
  useActivateEventPortifolioMutation,
  useDeactivateEventPortifolioMutation,
} from "../../../api/eventPortifolios/eventPortifoliosAPI";
import { setEventPortifolioDetails } from "../../../state/features/eventPortifolios/eventPortifoliosSlice";

const ActivateDeactivateEventPortifolio = ({ status, id }) => {
  // ############# CONST ##################
  const RTK = useRTK();
  const dispatch = useDispatch();

  // ############# RTK ##################
  const [
    activateAction,
    {
      isLoading: activateLoading,
      isSuccess: activateSuccess,
      isError: activateIsError,
      error: activateError,
      data: activateData,
    },
  ] = useActivateEventPortifolioMutation();
  const [
    deactivateAction,
    {
      isLoading: deactivateLoading,
      isSuccess: deactivateSuccess,
      isError: deactivateIsError,
      error: deactivateError,
      data: deactivateData,
    },
  ] = useDeactivateEventPortifolioMutation();

  // ############# useEffect ##################
  // => Activate res
  // ############# useEffect ##################
  const rtkActions = (data) => {
    dispatch(setEventPortifolioDetails(data));
  };
  RTK.useRTKResponse(
    activateSuccess,
    activateIsError,
    activateError,
    activateData,
    rtkActions
  );
  RTK.useRTKResponse(
    deactivateSuccess,
    deactivateIsError,
    deactivateError,
    deactivateData,
    rtkActions
  );
  return (
    <>
      <Tooltip title={parseInt(status) === 1 ? "Deactivate" : "Activate"} arrow>
        <Button
          variant="outlined"
          color="secondary"
          disabled={activateLoading || deactivateLoading ? true : false}
          onClick={() =>
            parseInt(status) === 1 ? deactivateAction(id) : activateAction(id)
          }
        >
          {activateLoading || deactivateLoading ? (
            <>
              <CircularProgress
                size={20}
                color="secondary"
                sx={{
                  my: 0.25,
                }}
              />
            </>
          ) : (
            <Switch
              color={parseInt(status) === 1 ? "success" : "error"}
              size="small"
              checked={parseInt(status) === 1 ? true : false}
            />
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default ActivateDeactivateEventPortifolio;
