import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../assets/utils/colors";

const Section = ({ head, subHead, children, bgColor }) => {
  return (
    <>
      <Box
        sx={{
          height: '100%',
          pt: { md: 10, xs: 5 },
          pb: { md: 10, xs: 5 },
          background: bgColor && bgColor,
          position: "relative",
        }}
      >
        <Grid container justifyContent={"center"} rowSpacing={{ md: 8, xs: 2 }}>
          {head && (
            <Grid item sm={6} xs={8}>
              <Box>
                <Typography
                  sx={{
                    fontSize: { md: 25, xs: 18 },
                    fontWeight: "bold",
                    textAlign: "center",
                    color: colors.textColor,
                    opacity: 0.8,
                    textTransform: "uppercase",
                    position: "relative",
                    ":before": {
                      content: "''",
                      height: 70,
                      width: { md: 100, xs: 25 },
                      position: "absolute",
                      left: { md: 150, xs: -50 },
                      borderBottom: `5px solid ${colors.bgColor6}`,
                      borderBottomLeftRadius: 20,
                    },
                    ":after": {
                      content: "''",
                      height: 70,
                      width: { md: 100, xs: 25 },
                      position: "absolute",
                      right: { md: 150, xs: -50 },
                      borderBottom: `5px solid ${colors.bgColor6}`,
                      borderBottomRightRadius: 20,
                    },
                  }}
                >
                  {head}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    textAlign: "center",
                    color: colors.textColor,
                    opacity: 0.8,
                  }}
                >
                  {subHead}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item sm={12} xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Section;
