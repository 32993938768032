import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { colors } from "../../assets/utils/colors";
import {
  AddAPhotoOutlined,
  EditTwoTone,
  ReadMore,
  RemoveRedEye,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { gallariesSelector } from "../../state/features/selectors";
import { useGetAllGallariesQuery } from "../../api/gallaries/gallariesAPI";
import { setGallaries } from "../../state/features/gallaries/gallariesSlice";
import CustomDialog from "../../components/dialogs/CustomDialog";
import CreateGallary from "./CRUD/CreateGallary";
import DeleteGallary from "./CRUD/DeleteGallary";
import ActivateDeactivateGallary from "./CRUD/ActivateDeactivateGallary";
import UpdateGallary from "./CRUD/UpdateGallary";
import { isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import GallaryView from "../../components/GallaryView";
import DataTable from "react-data-table-component";
import CustomCard2 from "../../components/cards/CustomCard2";
import CountBadge from "../../components/CountBadge";

const Gallaries = () => {
  // ############# Const ##############
  const RTK = useRTK();
  const dispatch = useDispatch();

  // ############# Comp State ############
  const [actionType, setActionType] = useState("");
  const [gallaryDetails, setGallaryDetails] = useState({});
  const [gallaryView, setGallaryView] = React.useState(null);
  const deviceWidth = useMediaQuery("(min-width:600px)");
  const [toggler, setToggler] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // ############ Redux state #################
  const { gallaries } = useSelector(gallariesSelector);

  // ############# FUNC ###############
  const handleGallaryView = (gallary) => {
    setToggler(!toggler);
    setGallaryView(gallary);
  };
  const handleDialogyOpen = (type) => {
    setActionType(type);
    setDialogOpen(true);
  };
  const handleDialogyClose = () => {
    setDialogOpen(false);
  };

  // ############# RTK ##################
  const {
    isLoading,
    isSuccess,
    data: responseData,
  } = useGetAllGallariesQuery();

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setGallaries(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  const tableColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Gallary details",
      width: "400px",
      selector: (row) => (
        <>
          <Box sx={{ display: "flex", whiteSpace: "nowrap" }}>
            <Avatar
              src={row?.image}
              variant="square"
              sx={{
                height: 120,
                width: 140,
                img: {
                  objectFit: "cover",
                },
                m: 1,
                ml: 0,
                border: `1px dotted ${colors.secondary}`,
                borderRadius: 2,
                cursor: "pointer",
              }}
              onClick={() => handleGallaryView(row)}
            />
            <Box
              sx={{
                m: 1,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography sx={{ fontSize: 12, whiteSpace: "wrap" }}>
                {row?.event}
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: "bold",
                  my: 1,
                  color: colors.secondary,
                }}
              >
                {row?.year}
              </Typography>
              <Chip
                label={row.status ? "Active" : "In-Active"}
                sx={{
                  mr: 1,
                  px: 1,
                  fontSize: 11,
                  fontWeight: "bold",
                  height: 19,
                }}
                size="small"
                color={row.status ? "success" : "warning"}
              />
            </Box>
          </Box>
        </>
      ),
    },
    {
      name: "Description",
      width: "400px",
      selector: (row) => (
        <Typography sx={{ fontSize: 12, whiteSpace: "wrap" }}>
          {row?.description || "N/A"}
        </Typography>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Box sx={{ mb: 2 }}>
            <ActivateDeactivateGallary status={row.status} id={row.id} />
          </Box>
          <Box>
            <IconButton
              size="small"
              sx={{
                p: 1,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
              }}
            >
              <RemoveRedEye
                color="info"
                sx={{ fontSize: 20 }}
                onClick={() => handleGallaryView(row)}
              />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                p: 1,
                mx: 2,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
              }}
            >
              <EditTwoTone
                color="warning"
                sx={{ fontSize: 20 }}
                onClick={() => {
                  let type = "update";
                  setGallaryDetails(row);
                  handleDialogyOpen(type);
                }}
              />
            </IconButton>
            <DeleteGallary gallary={row} />
          </Box>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <GallaryView toggler={toggler} gallary={gallaryView} />
      {/* ########### Dialog ############## */}
      <CustomDialog
        // customWidth={"md"}
        head={actionType === "create" ? "Create new gallary" : "Update gallary"}
        openDialog={dialogOpen}
        closeDialog={handleDialogyClose}
      >
        {actionType === "create" ? (
          <CreateGallary handleClose={handleDialogyClose} />
        ) : (
          <UpdateGallary
            gallary={gallaryDetails}
            handleClose={handleDialogyClose}
          />
        )}
      </CustomDialog>

      <PageLayout
        pageTitle={"Gallaries managements"}
        isLoading={isLoading}
        action={
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<AddAPhotoOutlined />}
            onClick={() => {
              let type = "create";
              handleDialogyOpen(type);
            }}
            sx={{
              py: { md: 1, xs: 1 },
              px: 2,
              fontWeight: "bold",
              fontSize: 12,
              mr: 4,
              borderRadius: `20px !important`,
            }}
          >
            Add new gallary
          </Button>
        }
      >
        {isEmpty(gallaries) ? (
          <>
            <NoContent message={"No gallary added"} />
          </>
        ) : (
          <>
            <Grid container>
              <Grid item sm={12}>
                <CustomCard2
                  title={"All gallaries"}
                  action={
                    <CountBadge total={size(gallaries)} item={"Gallaries"} />
                  }
                >
                  <DataTable
                    columns={tableColumns}
                    data={gallaries}
                    rowKey={(record) => record?.id}
                    pagination={{
                      total: size(gallaries),
                      current: 1,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      pageSizeOptions: [5, 10, 25, 50, 100],
                      defaultPageSize: 5,
                      defaultCurrent: 1,
                    }}
                  />
                </CustomCard2>
              </Grid>
            </Grid>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default Gallaries;
