import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../assets/utils/colors";
import { ArrowCircleRightOutlined } from "@mui/icons-material";

const CustomListItem = ({ desktopOpen, text, icon, url }) => {
  return (
    <React.Fragment>
      <NavLink to={url}>
        {({ isActive }) => (
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                height: 38,
                justifyContent: desktopOpen ? "initial" : "center",
                px: 1,
                transition: `.2s all ease-in-out`,
                borderLeft: `2px solid #000`,
                "&:hover": {
                  transition: `.2s all ease-in-out`,
                  borderLeft: `2px solid ${colors.secondary}`,
                  bgcolor: isActive ? colors.bgColor6 : "",
                },
                bgcolor: isActive ? colors.bgColor6 : "",
                borderRadius: 2,
                mb: 1,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2,
                  justifyContent: "center",
                  ".list-icon": {
                    fontSize: 22,
                  },
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 12,
                    fontWeight: "bold",
                    color: isActive ? "#000" : colors.bgColor6,
                  },
                }}
              />
              {isActive && <ArrowCircleRightOutlined color="secondary" />}
            </ListItemButton>
          </ListItem>
        )}
      </NavLink>
    </React.Fragment>
  );
};

export default CustomListItem;
