import React from "react";
import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { Message, Phone, PinDrop } from "@mui/icons-material";
import { reverse, take } from "lodash";
import image from "../../assets/media/images/g31.jpg";

const ProfileInfoWidget = () => {
  const contacts = [
    {
      id: 1,
      title: "Phone number",
      icon: <Phone className="icon" />,
      value: "+255 763 101022",
    },
    {
      id: 2,
      title: "Email us",
      icon: <Message className="icon" />,
      value: "info@thev-events.co.tz",
      border: true,
    },
    {
      id: 3,
      title: "Physical address",
      icon: <PinDrop className="icon" />,
      value: "Jamuhuri Ilala Dar es Salaam Tanzania",
    },
  ];

  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            background: `linear-gradient(180deg, ${colors.secondary}, #000)`,
          }}
        >
          <Grid container spacing={3} padding={2}>
            <Grid item sm={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={image}
                  variant="square"
                  sx={{
                    height: 100,
                    width: 70,
                    borderRadius: 2,
                    border: `2px solid ${colors.bgColor1}`,
                  }}
                />
                <Box sx={{ ml: 1 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 14,
                      whiteSpace: "wrap",
                      color: "#fff",
                      mb: 1,
                    }}
                  >
                    Ms. Vennita Mwita
                  </Typography>
                  <Box>
                    <Grid container spacing={1}>
                      {take(contacts, 2)?.map((contact) => (
                        <Grid item sm={12} xs={12} key={contact.id}>
                          <Typography
                            sx={{
                              fontSize: 10,
                              display: "flex",
                              alignItems: "center",
                              color: colors.bgColor6,
                              ".icon": {
                                fontSize: 12,
                                mr: 1,
                                color: colors.secondary,
                              },
                            }}
                          >
                            {contact.icon}
                            {contact.value}
                          </Typography>
                        </Grid>
                      ))}
                      {take(reverse(contacts), 1)?.map((contact) => (
                        <Grid item sm={12} xs={12} key={contact.id}>
                          <Typography
                            sx={{
                              fontSize: 10,
                              display: "flex",
                              alignItems: "center",
                              color: colors.bgColor6,
                              ".icon": {
                                fontSize: 12,
                                mr: 1,
                                color: colors.secondary,
                              },
                              whiteSpace: "wrap",
                            }}
                          >
                            {contact.icon}
                            {contact.value}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ pt: 2 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: 13,
                    whiteSpace: "wrap",
                    color: colors.bgColor6,
                  }}
                >
                  "I'm in love with creating unique experiences and connecting
                  people"
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ProfileInfoWidget;
