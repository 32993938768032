import React from 'react';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { colors } from '../../assets/utils/colors';

const CustomInput = styled(TextField)({
    padding: 0,
});

const CustomFileInput = ({
    formik,
    setFilePreview,
    setFile,
    label,
    type,
    accept,
    ...props
}) => {
    const handleFileInputChange = (e) => {
        setFilePreview(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue('file', e.currentTarget.files[0]);
    };
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2, borderRadius: 1.5, overflow: 'hidden' }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="outlined"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    onChange={handleFileInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ accept: accept }}
                    sx={{
                        '& .MuiFormLabel-root': {
                            color: colors.secondary,
                        },
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomFileInput;
