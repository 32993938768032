import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    eventTypes: [],
};

// !########## SLICE #############
const eventTypesSlice = createSlice({
    name: 'eventTypesSlice',
    initialState,
    reducers: {
        setEventTypes: (state, actions) => {
            state.eventTypes = actions.payload;
        },
    },
});

export const { setEventTypes } = eventTypesSlice.actions;
export default eventTypesSlice.reducer;
