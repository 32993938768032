import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/media/images/logo2.png";
import { colors } from "../assets/utils/colors";
import { Window } from "@mui/icons-material";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.drawer + 1,
  background: colors.bgColor2,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "#000",
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
  return (
    <>
      <AppBar
        sx={{
          bgcolor: "#000",
          position: "relative",
          borderBottom: `1px dotted ${colors.bgColor6}`,
          width: '100%'
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
          }}
        >
          {/* ################### LEFT ################# */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              noWrap
              component="div"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {/* <Avatar src={logo} /> */}
              <Typography
                sx={{
                  fontSize: 16,
                  color: colors.bgColor6,
                }}
              >
                <strong style={{ textTransform: "uppercase" }}>
                  The V-Events
                </strong>

                <Typography
                  component={"span"}
                  sx={{
                    fontSize: 12,
                    display: { md: "block", xs: "none" },
                  }}
                >
                  Admin dashboard
                </Typography>
              </Typography>
            </Typography>
          </Box>

          {/* ################### RIGHT ################# */}
          <TopbarRight
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              src={logo}
              sx={{
                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
              }}
            />
            {mobileOpen ? (
              <IconButton
                onClick={handleDrawerToggle}
                color="secondary"
                sx={{
                  display: { xs: "block", sm: "none" },
                  marginTop: 1.1,
                  ml: 2,
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleDrawerToggle}
                color="secondary"
                sx={{
                  display: { xs: "block", sm: "none" },
                  marginTop: 1.1,
                  ml: 2,
                }}
              >
                <Window />
              </IconButton>
            )}
          </TopbarRight>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
