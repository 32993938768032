import React from "react";
import { Avatar, Box, Button, Chip, Grid, styled } from "@mui/material";
import DashLayout from "./layouts/DashLayout";
import DashSidebar from "../components/dash/DashSidebar";
import GreatingCard from "../components/dash/GreatingCard";
import InfoBox from "../components/dash/InfoBox";
import { useSelector } from "react-redux";
import {
  clientsSelector,
  eventPortifoliosSelector,
  eventTypesSelector,
  gallariesSelector,
  partinersSelector,
} from "../state/features/selectors";
import { isEmpty, size } from "lodash";
import CustomCard2 from "../components/cards/CustomCard2";
import DataTable from "react-data-table-component";
import NoContent from "../components/NoContent";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";

const Dashboard = () => {
  const { gallaries } = useSelector(gallariesSelector);
  const { clients } = useSelector(clientsSelector);
  const { partiners } = useSelector(partinersSelector);
  const { eventTypes } = useSelector(eventTypesSelector);
  const { eventPortifolios } = useSelector(eventPortifoliosSelector);

  const tableColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tease image",
      width: "150px",
      selector: (row) => (
        <>
          <Avatar
            src={row?.tease_image}
            variant="square"
            sx={{
              height: 60,
              width: 90,
              img: {
                objectFit: "cover",
              },
              my: 1,
              border: `1px dotted ${colors.secondary}`,
              borderRadius: 2,
            }}
          />
        </>
      ),
    },
    {
      name: "Event title",
      selector: (row) => <p>{row?.event_title}</p>,
    },
    {
      name: "Client",
      selector: (row) => <p>{row?.client?.name}</p>,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Chip
            label={row.status ? "Active" : "In-Active"}
            sx={{ mr: 1 }}
            size="small"
            color={row.status ? "success" : "warning"}
          />
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`/event-portifolios/${row?.id}`}>
            <Button
              variant="outlined"
              size="small"
              color="info"
              startIcon={<RemoveRedEye />}
            >
              More details
            </Button>
          </Link>
        </>
      ),
    },
  ].filter(Boolean);

  const infoBox = [
    {
      id: 5,
      head: "Event managements",
      subHead1: "Events",
      total1: size(eventPortifolios),
      urlName: "Manage events",
      url: "/event-portifolios",
      btnColor: "success",
    },
    {
      id: 1,
      head: "Client managements",
      subHead1: "Clients",
      total1: size(clients),
      urlName: "Manage clients",
      url: "/clients",
      btnColor: "warning",
    },
    {
      id: 2,
      head: "Partiners managements",
      subHead1: "Partiners",
      total1: size(partiners),
      urlName: "Manage partiners",
      url: "/partiners",
      btnColor: "primary",
    },
    {
      id: 3,
      head: "Gallaries managements",
      subHead1: "Gallaries",
      total1: size(gallaries),
      urlName: "Manage gallaries",
      url: "/gallaries",
      btnColor: "info",
    },
    {
      id: 4,
      head: "Event types managements",
      subHead1: "Event types",
      total1: size(eventTypes),
      urlName: "Manage event types",
      url: "/event-types",
      btnColor: "error",
    },
  ];
  return (
    <>
      <Grid container padding={2} spacing={2} sx={{ pb: { md: 0, xs: 2 } }}>
        <Grid item sm={12} xs={12}>
          <GreatingCard />
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Grid container spacing={2}>
                {infoBox?.map((info, index) => (
                  <Grid item sm={2.4} xs={6} key={index}>
                    <InfoBox info={info} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <CustomCard2
                title={"Latest events"}
                height={isEmpty(eventPortifolios) ? 200 : "auto"}
              >
                {isEmpty(eventPortifolios) ? (
                  <>
                    <NoContent message={"No event added"} height={200} />
                  </>
                ) : (
                  <>
                    <Grid container>
                      <Grid item sm={12}>
                        <DataTable
                          columns={tableColumns}
                          data={eventPortifolios}
                          rowKey={(record) => record?.id}
                          pagination={{
                            total: size(eventPortifolios),
                            current: 1,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            pageSizeOptions: [5, 10, 25, 50, 100],
                            defaultPageSize: 5,
                            defaultCurrent: 1,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </CustomCard2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
