import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { clientsEndpoints } from "./clientsEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const clientsAPI = createApi({
  reducerPath: "clientsAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
  tagTypes: ["Clients"],
  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: () => `${clientsEndpoints.GET_ALL}`,
      providesTags: ["Clients"],
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getSingleClient: builder.query({
      query: (payload) => `${clientsEndpoints.GET_BY_ID}/${payload}`,
    }),
    createClient: builder.mutation({
      query: (payload) => ({
        url: `${clientsEndpoints.CREATE}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["Clients"],
    }),
    updateClient: builder.mutation({
      query: (payload) => ({
        url: `${clientsEndpoints.UPDATE}/${payload.get("client_id")}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["Clients"],
    }),
    activateClient: builder.mutation({
      query: (payload) => ({
        url: `${clientsEndpoints.ACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["Clients"],
    }),
    deactivateClient: builder.mutation({
      query: (payload) => ({
        url: `${clientsEndpoints.DEACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["Clients"],
    }),
    deleteClient: builder.mutation({
      query: (payload) => ({
        url: `${clientsEndpoints.DELETE}/${payload}`,
        method: `DELETE`,
      }),
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useGetSingleClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useActivateClientMutation,
  useDeactivateClientMutation,
  useDeleteClientMutation,

  // Lazy
  useLazyGetAllClientsQuery,
} = clientsAPI;
