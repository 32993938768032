// => GET all events
const GET_ALL = "/event-portifolios";

// => GET single event
const GET_BY_ID = "/event-portifolios";

// => CREATE event
const CREATE = "/event-portifolios";

// => UPDATE event
const UPDATE = "/event-portifolios";

// => ACTIVATE event
const ACTIVATE = "/event-portifolios/activate";

// => DEACTIVATE event
const DEACTIVATE = "/event-portifolios/deactivate";

// => DELETE event
const DELETE = "/event-portifolios";

// => CREATE event gallary
const CREATE_EVENT_GALLARY = "/event-portifolios/gallaries/add";

// => UPDATE event gallary
const UPDATE_EVENT_GALLARY = "/event-portifolios/gallaries/update";

// => DELETE event gallary
const DELETE_EVENT_GALLARY = "/event-portifolios/gallaries/delete";

export const eventPortifoliosEndpoints = {
  GET_ALL,
  GET_BY_ID,
  CREATE,
  UPDATE,
  ACTIVATE,
  DEACTIVATE,
  DELETE,
  CREATE_EVENT_GALLARY,
  UPDATE_EVENT_GALLARY,
  DELETE_EVENT_GALLARY,
};
