import React from 'react';
import { Avatar, Box, Grid, Typography, styled } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { Message, Phone, PinDrop } from '@mui/icons-material';
import ContactWidget from '../widgets/ContactWidget';
import { reverse, take } from 'lodash';
import image from '../../assets/media/images/g31.jpg';

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const DashSidebar = () => {
    const contacts = [
        {
            id: 1,
            title: 'Phone number',
            icon: <Phone className="icon" />,
            value: '+255 763 101022',
        },
        {
            id: 2,
            title: 'Email us',
            icon: <Message className="icon" />,
            value: 'info@thev-events.co.tz',
            border: true,
        },
        {
            id: 3,
            title: 'Physical address',
            icon: <PinDrop className="icon" />,
            value: 'Jamuhuri Ilala Dar es Salaam Tanzania',
        },
    ];
    return (
        <>
            <Box
                sx={{
                    top: 0,
                    position: { md: 'sticky', xs: 'relative' },
                    display: { md: 'block', xs: 'none' },
                }}
            >
                <Box
                    sx={{
                        position: { md: 'absolute', xs: 'relative' },
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        height: { md: '100vh', xs: '100%' },
                        overflow: 'auto',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    }}
                >
                    <DrawerHeader />
                    <Grid container spacing={3} padding={2}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Avatar
                                    src={image}
                                    sx={{ height: 200, width: 200 }}
                                />
                            </Box>
                            <Box sx={{ py: 2 }}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    Ms. Vennita Mwita
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 2 }}>
                                <Typography
                                    sx={{ textAlign: 'center', fontSize: 14 }}
                                >
                                    "I'm in love with creating unique
                                    experiences and connecting people"
                                </Typography>
                            </Box>
                            <Box sx={{ py: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                        pb: 1,
                                    }}
                                >
                                    Contact info's
                                </Typography>
                                <Grid container spacing={2}>
                                    {take(contacts, 2)?.map((contact) => (
                                        <Grid item sm={12} xs={12}>
                                            <ContactWidget
                                                key={contact.id}
                                                contact={contact}
                                            />
                                        </Grid>
                                    ))}
                                    {take(reverse(contacts), 1)?.map(
                                        (contact) => (
                                            <Grid item sm={12} xs={12}>
                                                <ContactWidget
                                                    key={contact.id}
                                                    contact={contact}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default DashSidebar;
