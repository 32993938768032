import React from 'react';
import { Person } from '@mui/icons-material';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import logo from '../../assets/media/images/logo2.png';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import HotToastfy from '../../components/HotToastfy';
import { errorNotify } from '../../helpers/notify';
import { useDispatch } from 'react-redux';
import { setUserProfile } from '../../state/features/auth/authSlice';
import useAuth from '../../hooks/useAuth';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import CustomTextField from '../../components/forms/CustomTextField';
import { useLoginMutation } from '../../api/auth/authAPI';
import * as Yup from 'yup';
import image from '../../assets/media/images/g49.png';
import badge from '../../assets/media/images/badge.jpg';

// ################### Validation ###############
const loginValidationSchema = Yup.object({
    phone_number: Yup.string().required('Agiza id is a required field'),
    password: Yup.string().required(),
});

const Login = () => {
    // ################### CONST #####################
    const auth = useAuth();
    const dispatch = useDispatch();

    // ################### RTK #######################
    const [login, { isLoading }] = useLoginMutation();

    // => Handle login
    const handleLogin = async (payload) => {
        let res = await login(payload);
        if (!isEmpty(res.data)) {
            dispatch(setUserProfile(res.data));
            auth.saveAuthenticatedUser(res.data);
        } else if (res.error) {
            return errorNotify(
                res.error.message
                    ? res.error.message
                    : 'Temporary server error. Please try again letter.',
            );
        } else {
            let message = res.error.data.message;
            return errorNotify(message);
        }
    };
    return (
        <>
            <HotToastfy />
            <Grid container>
                <Grid item sm={6} xs={12} sx={{ position: 'relative' }}>
                    <Avatar
                        src={badge}
                        sx={{
                            height: '100vh',
                            width: '100%',
                            opacity: { md: 0.5, xs: 0.1 },
                            zIndex: { md: 0, xs: 5 },
                            position: { md: 'absolute', xs: 'fixed' },
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                        variant="square"
                    />
                    <Box
                        sx={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            position: { md: 'static', xs: 'fixed' },
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            zIndex: { md: 0, xs: 10 },
                        }}
                    >
                        <Grid
                            container
                            justifyContent={'center'}
                            alignItems={'center'}
                            padding={{ md: 10, xs: 0 }}
                        >
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: { md: '100%', xs: '100vh' },
                                        px: { md: 5, xs: 2 },
                                        opacity: 0.9,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        // border: `15px solid ${colors.bgColor6}`,
                                        zIndex: 20,
                                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                p: { md: 3, xs: 1 },
                                                display: 'flex',
                                                justifyContent: 'center',
                                                // flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Avatar
                                                src={logo}
                                                sx={{
                                                    height: 150,
                                                    width: 150,
                                                    mb: 4,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 30,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    textTransform: 'uppercase',
                                                    color: colors.secondary,
                                                }}
                                            >
                                                The V-Events
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt: {
                                                    md: 1,
                                                    xs: 3,
                                                },
                                                pl: 2,
                                                borderLeft: `5px solid ${colors.secondary}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 22,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Welcome back
                                            </Typography>
                                            <Typography sx={{ fontSize: 15 }}>
                                                Sign in to your account
                                            </Typography>
                                        </Box>
                                        <Box sx={{ mt: { md: 1, xs: 3 } }}>
                                            <Formik
                                                initialValues={{
                                                    phone_number: '0763101022',
                                                    password: 'mwita@2023',
                                                }}
                                                validationSchema={
                                                    loginValidationSchema
                                                }
                                                onSubmit={(payload) => {
                                                    handleLogin(payload);
                                                }}
                                            >
                                                {(formik) => (
                                                    <Box className="input-card">
                                                        <Box sx={{ mb: 2 }}>
                                                            <Box>
                                                                <CustomTextField
                                                                    type="text"
                                                                    name="phone_number"
                                                                    label={
                                                                        'Type your phone number'
                                                                    }
                                                                />
                                                                <CustomTextField
                                                                    type="password"
                                                                    name="password"
                                                                    label={
                                                                        'Type your Password'
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                'mt': 3,
                                                                'mb': 5,
                                                                'width': '100%',
                                                                'display':
                                                                    'flex',
                                                                'flexDirection':
                                                                    'column',
                                                                'justifyContent':
                                                                    'center',
                                                                'borderRight': `none`,
                                                                '& .head': {
                                                                    fontSize: 20,
                                                                    fontWeight:
                                                                        'bold',
                                                                    mb: 1,
                                                                },
                                                                '& .item': {
                                                                    'display':
                                                                        'flex',
                                                                    'alignItems':
                                                                        'center',
                                                                    'fontSize': 13,
                                                                    'mb': 0.5,
                                                                    '& .icon': {
                                                                        mr: 1,
                                                                        color:
                                                                            colors.secondary,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <CustomSubmitButton
                                                                loading={
                                                                    isLoading
                                                                }
                                                                onClick={
                                                                    formik.handleSubmit
                                                                }
                                                                startIcon={
                                                                    <Person />
                                                                }
                                                                btnColor={
                                                                    'secondary'
                                                                }
                                                            >
                                                                Sign in
                                                            </CustomSubmitButton>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Formik>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                    sx={{
                        position: 'relative',
                        display: { md: 'none', xs: 'block' },
                    }}
                >
                    <Box
                        sx={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            background: 'transparent',
                        }}
                    ></Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Box
                        sx={{
                            height: '100vh',
                            position: 'relative',
                        }}
                    >
                        <Avatar
                            src={image}
                            variant="square"
                            sx={{ height: '100vh', width: '100%' }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                background: `rgb(236, 246, 255, .2)`,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Typography
                                sx={{
                                    px: 5,
                                    py: 3,
                                    textAlign: 'center',
                                    fontSize: 20,
                                    opacity: 0.8,
                                    // borderTopRightRadius: 50,
                                    // borderTopLeftRadius: 50,
                                    fontStyle: 'italic',
                                    background: `linear-gradient(180deg, rgb(236, 246, 255, .1), ${colors.bgColor6})`,
                                }}
                            >
                                <Typography
                                    component={'span'}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 30,
                                        opacity: 0.9,
                                        fontStyle: "normal",
                                    }}
                                >
                                    Ms. Mwita
                                </Typography>
                                <br />
                                "I'm in love with creating unique experiences
                                and connecting people"
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Login;
