import { systemConfigSelector } from '../state/features/selectors';
import { useSelector } from 'react-redux';

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    const lightColor = {
        primary: '#bde0fe',
        secondary: '#fca311',
        textColor: '#14213d',
        bgColor1: '#ecf6ff',
        bgColor2: '#c5e4fe',
        bgColor3: '#caf0f8',
        bgColor4: '#fffaf2',
        bgColor5: '#feedd0',
        bgColor6: '#fee1b0',
        yellow: '#FFD900',
        green: '#00EE76',
        red: '#F43249',
        gray: '#D9D9D9',
        blue: '#027BBD',
    };

    const darkColor = {
        primary: '#fff',
        secondary: '#fca311',
        bgColor1: '#0d1b2a',
        bgColor2: '#0d1b2a',
        bgColor3: '#0d1b2a',
        bgColor4: '#0d1b2a',
        yellow: '#FFD900',
        green: '#00EE76',
        red: '#F43249',
        gray: '#D9D9D9',
        blue: '#027BBD',
    };

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

export default useColor;
