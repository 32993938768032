import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
  getRTKErrorMessage,
  getRTKResponseData,
  getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { useDispatch, useSelector } from "react-redux";
import { setGallaries } from "../state/features/gallaries/gallariesSlice";
import { useLazyGetAllGallariesQuery } from "../api/gallaries/gallariesAPI";
import { useLazyGetAllClientsQuery } from "../api/clients/clientsAPI";
import { useLazyGetAllPartinersQuery } from "../api/partiners/partinersAPI";
import { setClients } from "../state/features/clients/clientsSlice";
import { setPartiners } from "../state/features/partiners/partinersSlice";
import { setEventTypes } from "../state/features/eventTypes/eventTypesSlice";
import { useLazyGetAllEventTypesQuery } from "../api/eventTypes/eventTypesAPI";
import { setEventPortifolios } from "../state/features/eventPortifolios/eventPortifoliosSlice";
import { useLazyGetAllEventPortifoliosQuery } from "../api/eventPortifolios/eventPortifoliosAPI";
import { disableMainFetch } from "../state/features/systemConfig/systemConfigSlice";
import { systemConfigSelector } from "../state/features/selectors";

const useRTK = () => {
  const { isMainFetch } = useSelector(systemConfigSelector);

  const useRTKResponse = (isSuccess, isError, error, data, actions) => {
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isSuccess) {
          let newData = data;
          if (!newData) {
            newData = [];
          }
          actions && actions(getRTKResponseData(newData));
          successNotify(getRTKSuccessMessage(data));
        }
        if (isError) {
          errorNotify(getRTKErrorMessage(error));
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, [isSuccess, isError, error, data]);
  };

  const useRTKFetch = (isSuccess, data, actions) => {
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isSuccess) {
          actions(getRTKResponseData(data));
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, [isSuccess, data]);
  };

  const useRTKMainData = () => {
    // => CONST
    const dispatch = useDispatch();

    // ################# Gallaries #################
    const [
      getAllGallaries,
      {
        isLoading: gallariesLoading,
        isSuccess: gallariesSuccess,
        data: gallariesData,
      },
    ] = useLazyGetAllGallariesQuery();
    const rtkGallariesActions = (data) => {
      dispatch(setGallaries(data));
    };
    useRTKFetch(gallariesSuccess, gallariesData, rtkGallariesActions);

    // ################# Clients #################
    const [
      getAllClients,
      {
        isLoading: clientsLoading,
        isSuccess: clientsSuccess,
        data: clientsData,
      },
    ] = useLazyGetAllClientsQuery();
    const rtkClientsActions = (data) => {
      dispatch(setClients(data));
    };
    useRTKFetch(clientsSuccess, clientsData, rtkClientsActions);

    // ################# Partiners #################
    const [
      getAllPartiners,
      {
        isLoading: partinersLoading,
        isSuccess: partinersSuccess,
        data: partinersData,
      },
    ] = useLazyGetAllPartinersQuery();
    const rtkPartinersActions = (data) => {
      dispatch(setPartiners(data));
    };
    useRTKFetch(partinersSuccess, partinersData, rtkPartinersActions);

    // ################# EventTypes #################
    const [
      getAllEventTypes,
      {
        isLoading: eventTypesLoading,
        isSuccess: eventTypesSuccess,
        data: eventTypesData,
      },
    ] = useLazyGetAllEventTypesQuery();
    const rtkEventTypesActions = (data) => {
      dispatch(setEventTypes(data));
    };
    useRTKFetch(eventTypesSuccess, eventTypesData, rtkEventTypesActions);

    // ################# Events #################
    const [
      getAllEventPortifolios,
      { isLoading: eventsLoading, isSuccess: eventsSuccess, data: eventsData },
    ] = useLazyGetAllEventPortifoliosQuery();
    const rtkEventsActions = (data) => {
      dispatch(setEventPortifolios(data));
    };
    useRTKFetch(eventsSuccess, eventsData, rtkEventsActions);

    // ############## useEffect #################
    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
        if (isMainFetch) {
          getAllGallaries();
          getAllClients();
          getAllPartiners();
          getAllEventTypes();
          getAllEventPortifolios();
          setTimeout(() => {
            dispatch(disableMainFetch());
          }, 2000);
        }
      }
      return () => {
        isSubscribed = false;
      };
    }, []);

    return gallariesLoading
      ? gallariesLoading
      : clientsLoading
      ? clientsLoading
      : partinersLoading
      ? partinersLoading
      : eventTypesLoading
      ? eventTypesLoading
      : eventsLoading
      ? eventsLoading
      : false;
  };

  return {
    useRTKFetch,
    useRTKResponse,
    useRTKMainData,
  };
};

export default useRTK;
