import React from "react";
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import { ArrowCircleLeftOutlined, Window } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader";

const DrawerHeader = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const PageLayout = ({ children, sidebar, pageTitle, action, isLoading }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          p: 2,
          height: 68,
          width: "100%",
          display: "flex",
          alignItems: "center",
          zIndex: 1024,
          justifyContent: "space-between",
          borderBottom: `1px dotted ${colors.bgColor6}`,
          background: `linear-gradient(180deg, #000, #000)`,
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            color: colors.bgColor1,
          }}
        >
          <Window sx={{ mr: 1 }} color="secondary" />
          {pageTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {action}
          <IconButton
            size="small"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowCircleLeftOutlined sx={{ fontSize: 35 }} color="secondary" />
          </IconButton>
        </Box>
      </Box>
      {isLoading ? (
        <>
          <PageLoader />
        </>
      ) : (
        <>
          <Box sx={{ flex: 1, overflowY: "auto" }}>{children}</Box>
        </>
      )}
    </>
  );
};

export default PageLayout;
