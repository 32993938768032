import { authAPI } from "../../api/auth/authAPI";
import { clientsAPI } from "../../api/clients/clientsAPI";
import { eventPortifoliosAPI } from "../../api/eventPortifolios/eventPortifoliosAPI";
import { eventTypesAPI } from "../../api/eventTypes/eventTypesAPI";
import { gallariesAPI } from "../../api/gallaries/gallariesAPI";
import { partinersAPI } from "../../api/partiners/partinersAPI";

// => RTK middleware
export const RTKmiddleware = [
  authAPI.middleware,
  gallariesAPI.middleware,
  clientsAPI.middleware,
  partinersAPI.middleware,
  eventTypesAPI.middleware,
  eventPortifoliosAPI.middleware,
];
