import { Box, CircularProgress, Button, Tooltip } from "@mui/material";
import React from "react";
import { colors } from "../../../assets/utils/colors";
import { DeleteForever } from "@mui/icons-material";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEventPortifolios } from "../../../state/features/eventPortifolios/eventPortifoliosSlice";
import { useDeleteEventPortifolioMutation } from "../../../api/eventPortifolios/eventPortifoliosAPI";

const DeleteEventPortifolio = ({ event }) => {
  // ############# CONST ##################
  const RTK = useRTK();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ############# RTK ##################
  const [
    deleteGallary,
    {
      isLoading: deleteLoading,
      isSuccess: deleteSuccess,
      isError: deleteIsError,
      error: deleteError,
      data: deleteData,
    },
  ] = useDeleteEventPortifolioMutation();
  // ############# useEffect ##################
  const rtkActions = (data) => {
    navigate("/event-portifolios");
  };
  RTK.useRTKResponse(
    deleteSuccess,
    deleteIsError,
    deleteError,
    deleteData,
    rtkActions
  );
  return (
    <Tooltip title={"Delete event"} arrow placement="top">
      <Button
        variant="outlined"
        size="small"
        onClick={() => deleteGallary(event.id)}
        disabled={deleteLoading ? true : false}
        sx={{
          p: 1,
          background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
        }}
      >
        <DeleteForever color="error" sx={{ fontSize: 20 }} />
        {deleteLoading && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
            }}
          >
            <CircularProgress
              size={23}
              color="secondary"
              sx={{
                my: 0.25,
                mt: 1.3,
              }}
            />
          </Box>
        )}
      </Button>
    </Tooltip>
  );
};

export default DeleteEventPortifolio;
