import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomMultlineTextField from '../../../components/forms/CustomMultlineTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import { Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/forms/CustomFileInput';
import { colors } from '../../../assets/utils/colors';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { useUpdateClientMutation } from '../../../api/clients/clientsAPI';
import { setClients } from '../../../state/features/clients/clientsSlice';

const updateClientValidationSchema = Yup.object({
    file: Yup.string(),
    name: Yup.string().required(),
    year: Yup.number().required(),
});

const UpdateClient = ({ handleClose, client }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState(client.image);

    // ############# RTK ##################
    const [
        updateClient,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useUpdateClientMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setClients(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: '',
                        name: client.name,
                        year: client.year,
                        description: client.description,
                    }}
                    validationSchema={updateClientValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('client_id', client.id);
                        payload.append('image', values.file);
                        payload.append('name', values.name);
                        payload.append('year', values.year);
                        updateClient(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} sx={{ pt: 1 }}>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item sm={12}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item sm={12}>
                                            <CustomFileInput
                                                name="file"
                                                type="file"
                                                formik={formik}
                                                accept="image/*"
                                                setFilePreview={setFilePreview}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={8} xs={12}>
                                    <CustomTextField
                                        name="name"
                                        type={'text'}
                                        label={'Client'}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        name="year"
                                        type={'text'}
                                        label={'year'}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                    >
                                        Update client
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdateClient;
