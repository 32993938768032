import React from 'react';
import { Select } from '@mui/material';
import { Box, FormControl, InputLabel } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

const CustomSelect = ({ formik, label, color, children, ...props }) => {
    const [field, meta] = useField(props);
    const [selectValue, setSelectValue] = React.useState(
        field.value ? field.value : '',
    );

    const handleChange = (e) => {
        setSelectValue(e.target.value);
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, e.target.value);
    };

    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <FormControl
                    variant="outlined"
                    sx={{
                        minWidth: '100%',
                    }}
                    size="small"
                >
                    <InputLabel id={label}>{label}</InputLabel>
                    <Select
                        className={`${meta.touched && meta.error && 'error'}`}
                        {...field}
                        {...props}
                        minRows={3}
                        placeholder={label}
                        value={selectValue}
                        onChange={handleChange}
                        variant="outlined"
                        label={label}
                        sx={{ color: `${color ? color : 'gray'}` }}
                    >
                        {children}
                    </Select>
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomSelect;
