import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { colors } from "../../assets/utils/colors";
import {
  EditTwoTone,
  PictureInPictureAltOutlined,
  ReadMore,
} from "@mui/icons-material";
import DeleteEventPortifolio from "./CRUD/DeleteEventPortifolio";
import ActivateDeactivateEventPortifolio from "./CRUD/ActivateDeactivateEventPortifolio";
import { useDispatch, useSelector } from "react-redux";
import { eventPortifoliosSelector } from "../../state/features/selectors";
import { useGetSingleEventPortifolioQuery } from "../../api/eventPortifolios/eventPortifoliosAPI";
import { useParams } from "react-router-dom";
import { setEventPortifolioDetails } from "../../state/features/eventPortifolios/eventPortifoliosSlice";
import useRTK from "../../hooks/useRTK";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import Section from "../../components/Section";
import EventGallaryView from "../../components/EventGallaryView";
import moment from "moment";
import CustomDialog from "../../components/dialogs/CustomDialog";
import CreateGallary from "./CRUD/CreateGallary";
import DeleteGallary from "./CRUD/DeleteGallary";
import UpdateGallary from "./CRUD/UpdateGallary";
import UpdateEventPortifolio from "./CRUD/UpdateEventPortifolio";

const EventPortifolioDetails = () => {
  // ############## CONST ##################
  const dispatch = useDispatch();
  const { event_id } = useParams();
  const RTK = useRTK();

  // ############# Comp State ############
  const [actionType, setActionType] = useState("");
  const [gallaryDetails, setGallaryDetails] = useState({});
  const [gallaryView, setGallaryView] = React.useState(null);
  const deviceWidth = useMediaQuery("(min-width:600px)");
  const [toggler, setToggler] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // ############### Redux State ###################
  const { eventPortifolioDetails } = useSelector(eventPortifoliosSelector);

  // ############ RTK ###################
  const {
    isLoading,
    isSuccess,
    data: responseData,
  } = useGetSingleEventPortifolioQuery(event_id);

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setEventPortifolioDetails(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  // ############# FUNC ###############
  const handleGallaryView = (gallary) => {
    setToggler(!toggler);
    setGallaryView(gallary);
  };
  const handleDialogyOpen = (type) => {
    setActionType(type);
    setDialogOpen(true);
  };
  const handleDialogyClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <EventGallaryView toggler={toggler} gallary={gallaryView} />
      {/* ########### Dialog ############## */}
      <CustomDialog
        // customWidth={"md"}
        head={
          actionType === "create"
            ? "Create event gallary"
            : actionType === "update"
            ? "Update event gallary"
            : "Update event details"
        }
        openDialog={dialogOpen}
        closeDialog={handleDialogyClose}
      >
        {actionType === "create" ? (
          <CreateGallary
            handleClose={handleDialogyClose}
            eventID={eventPortifolioDetails?.id}
          />
        ) : actionType === "update" ? (
          <UpdateGallary
            gallary={gallaryDetails}
            handleClose={handleDialogyClose}
          />
        ) : (
          <>
            <UpdateEventPortifolio
              event={eventPortifolioDetails}
              handleClose={handleDialogyClose}
            />
          </>
        )}
      </CustomDialog>

      <PageLayout pageTitle={"Event portifolio details"} isLoading={isLoading}>
        {eventPortifolioDetails && (
          <Box
            sx={{
              position: "fixed",
              right: { md: 20, xs: 10 },
              bottom: { md: 20, xs: 10 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              overflow: "hidden",
              zIndex: 999,
              border: `2px dotted ${colors.secondary}`,
            }}
          >
            <Box
              sx={{
                height: 70,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                gap: 2,
                p: 1,
              }}
            >
              <ActivateDeactivateEventPortifolio
                status={eventPortifolioDetails.status}
                id={eventPortifolioDetails.id}
              />
              <Tooltip title={"Add event gallary"} arrow placement="top">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    p: 1,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                  }}
                >
                  <PictureInPictureAltOutlined
                    color="warning"
                    sx={{ fontSize: 20 }}
                    onClick={() => {
                      let type = "create";
                      handleDialogyOpen(type);
                    }}
                  />
                </Button>
              </Tooltip>
              <Tooltip title={"Update event"} arrow placement="top">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    p: 1,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                  }}
                >
                  <EditTwoTone
                    color="warning"
                    sx={{ fontSize: 20 }}
                    onClick={() => {
                      let type = "update-event";
                      handleDialogyOpen(type);
                    }}
                  />
                </Button>
              </Tooltip>
              <DeleteEventPortifolio event={eventPortifolioDetails} />
            </Box>
          </Box>
        )}
        {isEmpty(eventPortifolioDetails) ? (
          <>
            <NoContent message={"Event details not found"} />
          </>
        ) : (
          <>
            <Grid container padding={2} spacing={2}>
              <Grid item sm={12}>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 5,
                    border: `1px dotted ${colors.secondary}`,
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item sm={12}>
                      <Typography
                        sx={{ fontSize: 25, fontWeight: "bold", opacity: 0.8 }}
                      >
                        {eventPortifolioDetails?.event_title}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Box
                        sx={{
                          height: 300,
                          p: 2,
                          borderRadius: 2,
                          background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.secondary})`,
                        }}
                      >
                        <Avatar
                          src={eventPortifolioDetails?.tease_image}
                          variant="square"
                          sx={{
                            width: "100%",
                            height: "100%",
                            img: { objectFit: "contain" },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item sm={6}>
                      <Grid
                        container
                        spacing={5}
                        sx={{ height: 300, justifyContent: "space-between" }}
                      >
                        <Grid item sm={6}>
                          <Box
                            sx={{
                              borderLeft: `5px solid ${colors.primary}`,
                              paddingLeft: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.secondary,
                              }}
                            >
                              Event Client
                            </Typography>
                            <Typography>
                              {eventPortifolioDetails?.client.name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Box
                            sx={{
                              borderLeft: `5px solid ${colors.primary}`,
                              paddingLeft: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.secondary,
                              }}
                            >
                              Event Type
                            </Typography>
                            <Typography>
                              {" "}
                              {eventPortifolioDetails?.event_type.title}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Box
                            sx={{
                              borderLeft: `5px solid ${colors.primary}`,
                              paddingLeft: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.secondary,
                              }}
                            >
                              Event Date
                            </Typography>
                            <Typography>
                              {moment(
                                eventPortifolioDetails?.event_date
                              ).format("LL")}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6}>
                          <Box
                            sx={{
                              borderLeft: `5px solid ${colors.primary}`,
                              paddingLeft: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.secondary,
                              }}
                            >
                              Event Venue
                            </Typography>
                            <Typography>
                              {eventPortifolioDetails?.event_venue}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: colors.secondary,
                          }}
                        >
                          Event description
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                          {eventPortifolioDetails?.event_description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Section head={"Event gallary"}>
                  {isEmpty(eventPortifolioDetails.gallaries) ? (
                    <>
                      <Box sx={{ height: 200 }}>
                        <NoContent message={"No gallary added"} />
                      </Box>
                    </>
                  ) : (
                    <Grid container paddingX={3}>
                      <Grid
                        item
                        sm={12}
                        sx={{
                          mb: { md: 15, xs: 10 },
                          mt: { md: 0, xs: 10 },
                        }}
                      >
                        <ImageList gap={15} cols={deviceWidth ? 4 : 2}>
                          {eventPortifolioDetails?.gallaries.map(
                            (gallary, index) => (
                              <Box sx={{}}>
                                <ImageListItem
                                  key={index}
                                  sx={{
                                    borderRadius: { md: 2, xs: 2 },
                                    overflow: "hidden",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleGallaryView(gallary)}
                                >
                                  <img
                                    src={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                    alt={gallary.title}
                                    loading="lazy"
                                    style={{
                                      height: 200,
                                    }}
                                  />
                                  <ImageListItemBar
                                    title={
                                      <Typography noWrap sx={{ fontSize: 13 }}>
                                        {gallary.title}
                                      </Typography>
                                    }
                                    subtitle={`@ ${moment(
                                      gallary.created_at
                                    ).format("YYYY")}`}
                                    actionIcon={
                                      <IconButton
                                        sx={{
                                          color: "rgba(255, 255, 255, 0.54)",
                                        }}
                                        onClick={() =>
                                          handleGallaryView(gallary)
                                        }
                                      >
                                        <ReadMore color="secondary" />
                                      </IconButton>
                                    }
                                  />
                                </ImageListItem>
                                <Box
                                  sx={{
                                    height: 50,
                                    mt: -1,
                                    borderBottomLeftRadius: 10,
                                    borderBottomRightRadius: 10,
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    pt: 1,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      p: 1,
                                      background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    }}
                                  >
                                    <EditTwoTone
                                      color="warning"
                                      sx={{ fontSize: 20 }}
                                      onClick={() => {
                                        let type = "update";
                                        setGallaryDetails(gallary);
                                        handleDialogyOpen(type);
                                      }}
                                    />
                                  </IconButton>
                                  <DeleteGallary gallary={gallary} />
                                </Box>
                              </Box>
                            )
                          )}
                        </ImageList>
                      </Grid>
                    </Grid>
                  )}
                </Section>
              </Grid>
            </Grid>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default EventPortifolioDetails;
