import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { Link } from 'react-router-dom';

const InfoBox = ({ info }) => {
    return (
        <>
            <Box
                sx={{
                    p: 1.5,
                    pb: 2,
                    borderRadius: 4,
                    boxShadow: `1px 1px 5px 0px ${colors.bgColor5}`,
                    border: `1px dotted gray`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    noWrap
                    sx={{
                        pt: 1,
                        pb: 1,
                        fontSize: 12,
                        opacity: 0.7,
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        letterSpacing: 1,
                        borderBottom: `2px dotted ${colors.secondary}`,
                    }}
                >
                    {info.head}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: 75,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderRadius: 5,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 25,
                                fontWeight: 'bold',
                                color: colors.secondary,
                                opacity: 0.8,
                            }}
                        >
                            {info.total1}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                // color: colors.secondary,
                                opacity: 0.8,
                            }}
                        >
                            {info.subHead1}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        mt: 2,
                    }}
                >
                    <Link to={info.url}>
                        <Button
                            fullWidth
                            variant="contained"
                            color={info.btnColor}
                            sx={{ fontSize: 12, whiteSpace: 'nowrap' }}
                        >
                            {info.urlName}
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default InfoBox;
