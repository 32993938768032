import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { partinersEndpoints } from "./partinersEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const partinersAPI = createApi({
  reducerPath: "partinersAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
  tagTypes: ["Partiners"],
  endpoints: (builder) => ({
    getAllPartiners: builder.query({
      query: () => `${partinersEndpoints.GET_ALL}`,
      providesTags: ["Partiners"],
    }),
    getSinglePartiner: builder.query({
      query: (payload) => `${partinersEndpoints.GET_BY_ID}/${payload}`,
    }),
    createPartiner: builder.mutation({
      query: (payload) => ({
        url: `${partinersEndpoints.CREATE}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["Partiners"],
    }),
    updatePartiner: builder.mutation({
      query: (payload) => ({
        url: `${partinersEndpoints.UPDATE}/${payload.get("partiner_id")}`,
        method: `POST`,
        body: payload,
      }),
      invalidatesTags: ["Partiners"],
    }),
    activatePartiner: builder.mutation({
      query: (payload) => ({
        url: `${partinersEndpoints.ACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["Partiners"],
    }),
    deactivatePartiner: builder.mutation({
      query: (payload) => ({
        url: `${partinersEndpoints.DEACTIVATE}/${payload}`,
        method: `PUT`,
      }),
      invalidatesTags: ["Partiners"],
    }),
    deletePartiner: builder.mutation({
      query: (payload) => ({
        url: `${partinersEndpoints.DELETE}/${payload}`,
        method: `DELETE`,
      }),
      invalidatesTags: ["Partiners"],
    }),
  }),
});

export const {
  useGetAllPartinersQuery,
  useGetSinglePartinerQuery,
  useCreatePartinerMutation,
  useUpdatePartinerMutation,
  useActivatePartinerMutation,
  useDeactivatePartinerMutation,
  useDeletePartinerMutation,

  // Lazy
  useLazyGetAllPartinersQuery,
} = partinersAPI;
