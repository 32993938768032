import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { useDate } from "../../hooks/useDate";
import { useSelector } from "react-redux";
import { authSelector } from "../../state/features/selectors";
import moment from "moment";

const GreatingCard = () => {
  const date = useDate();
  const { user } = useSelector(authSelector);

  return (
    <>
      <Box
        sx={{
          height: 100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          borderRadius: 2,
          boxShadow: `1px 1px 5px -2px ${colors.bgColor6}`,
          background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { md: 16, xs: 14 },
              fontWeight: "bold",
              opacity: 0.6,
            }}
          >
            Hello! {date.wish_1} {user.name}
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              color: colors.secondary,
              mt: 1,
            }}
          >
            Welcome back to your dashboard
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: { md: 20, xs: 16 },
              fontWeight: "bold",
              // color: colors.secondary,
              opacity: 0.6,
            }}
          >
            {date.time}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: "bold",
              // color: colors.secondary,
              opacity: 0.6,
              mt: 1,
            }}
          >
            {moment(date.date).format("LL")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default GreatingCard;
