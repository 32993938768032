import React from "react";
import { Box, FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";
import { colors } from "../../assets/utils/colors";

const CustomInput = styled(TextField)({});

const CustomTextField = ({ label, type, value, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <FormControl size="small" fullWidth>
          <CustomInput
            error={meta.touched && meta.error ? true : false}
            label={label}
            variant="outlined"
            fullWidth
            type={type}
            focused={type === "date" ? true : false}
            placeholder={label}
            {...field}
            {...props}
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                color: 'gray',
              },
            }}
          />
        </FormControl>
        <ErrorMessage
          component="span"
          name={field.name}
          className="error-text"
        />
      </Box>
    </>
  );
};

export default CustomTextField;
