import { Box, CircularProgress, IconButton } from '@mui/material';
import React from 'react';
import { colors } from '../../../assets/utils/colors';
import { DeleteForever } from '@mui/icons-material';
import { setGallaries } from '../../../state/features/gallaries/gallariesSlice';
import { useDeleteGallaryMutation } from '../../../api/gallaries/gallariesAPI';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';

const DeleteGallary = ({ gallary }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deleteGallary,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteGallaryMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setGallaries(data));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );
    return (
        <IconButton
            size="small"
            onClick={() => deleteGallary(gallary.id)}
            disabled={deleteLoading ? true : false}
            sx={{
                p: 1,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
            }}
        >
            <DeleteForever color="error" sx={{ fontSize: 20 }} />
            {deleteLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                    }}
                >
                    <CircularProgress
                        size={23}
                        color="secondary"
                        sx={{
                            my: 0.25,
                            mt: 1.3,
                        }}
                    />
                </Box>
            )}
        </IconButton>
    );
};

export default DeleteGallary;
