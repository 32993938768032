import { Box, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomMultlineTextField from "../../../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import { Image, Save } from "@mui/icons-material";
import CustomFileInput from "../../../components/forms/CustomFileInput";
import { colors } from "../../../assets/utils/colors";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import {
  clientsSelector,
  eventTypesSelector,
} from "../../../state/features/selectors";
import CustomSelect from "../../../components/forms/CustomSelect";
import { useCreateEventPortifolioMutation } from "../../../api/eventPortifolios/eventPortifoliosAPI";
import { setEventPortifolios } from "../../../state/features/eventPortifolios/eventPortifoliosSlice";

const createEventValidationSchema = Yup.object({
  file: Yup.string().required(),
  event_type_id: Yup.string().required("Event type is required"),
  event_client_id: Yup.string().required("Client is required"),
  event_title: Yup.string().required(),
  event_date: Yup.string().required(),
  event_venue: Yup.string().required(),
  event_description: Yup.string().required(),
});

const CreateEventPortifolio = ({ handleClose }) => {
  // ############# CONST ##################
  const RTK = useRTK();
  const dispatch = useDispatch();

  // ############# Comp state ################
  const [filePreview, setFilePreview] = useState("");

  // ############# RTK ##################
  const { clients } = useSelector(clientsSelector);
  const { eventTypes } = useSelector(eventTypesSelector);

  const [
    createEventPortifolio,
    { isLoading, isSuccess: addSuccess, isError, error, data: addData },
  ] = useCreateEventPortifolioMutation();

  // ############# useEffect ##################
  const rtkActions = (data) => {
    handleClose();
    dispatch(setEventPortifolios(data));
  };
  RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

  return (
    <>
      <Box>
        <Formik
          initialValues={{
            file: "",
            event_type_id: "",
            event_client_id: "",
            event_title: "",
            event_date: "",
            event_venue: "",
            event_description: "",
          }}
          validationSchema={createEventValidationSchema}
          onSubmit={(values) => {
            let payload = new FormData();
            payload.append("tease_image", values.file);
            payload.append("event_type_id", values.event_type_id);
            payload.append("event_client_id", values.event_client_id);
            payload.append("event_title", values.event_title);
            payload.append("event_date", values.event_date);
            payload.append("event_venue", values.event_venue);
            payload.append("event_description", values.event_description);
            createEventPortifolio(payload);
          }}
        >
          {(formik) => (
            <Form>
              <Grid container justifyContent={"center"} columnSpacing={2}>
                <Grid item sm={12} sx={{ pt: 1 }}>
                  <Grid container justifyContent={"center"}>
                    <Grid item sm={6}>
                      {filePreview ? (
                        <>
                          <img
                            src={filePreview}
                            alt="img"
                            style={{
                              width: "100%",
                              height: "10em",
                              objectFit: "contain",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Image
                            sx={{
                              width: "100%",
                              height: "7em",
                              objectFit: "contain",
                              opacity: 0.7,
                              color: colors.secondary,
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      <CustomFileInput
                        name="file"
                        type="file"
                        formik={formik}
                        accept="image/*"
                        setFilePreview={setFilePreview}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomSelect
                    label={"Select event type"}
                    name="event_type_id"
                    color={colors.secondary}
                    formik={formik}
                  >
                    {eventTypes?.map((type) => (
                      <MenuItem value={type.id}>{type.title}</MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomSelect
                    label={"Select event client"}
                    name="event_client_id"
                    color={colors.secondary}
                    formik={formik}
                  >
                    {clients?.map((client) => (
                      <MenuItem value={client.id}>{client.name}</MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <CustomTextField
                    name="event_title"
                    type={"text"}
                    label={"Event title"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextField
                    name="event_date"
                    type={"date"}
                    label={"Event date"}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomTextField
                    name="event_venue"
                    type={"text"}
                    label={"Event venue"}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <CustomMultlineTextField
                    name="event_description"
                    type={"text"}
                    label={"Descriptions"}
                    rows={4}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  sx={{
                    mt: 2,
                  }}
                >
                  <CustomSubmitButton
                    loading={isLoading}
                    startIcon={<Save />}
                    onClick={formik.handleSubmit}
                    btnColor={"primary"}
                  >
                    Save Event
                  </CustomSubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default CreateEventPortifolio;
