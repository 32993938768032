import React, { useEffect, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Avatar, Box, Button, Chip, Grid, IconButton } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { Add, EditTwoTone } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { partinersSelector } from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty, size } from "lodash";
import NoContent from "../../components/NoContent";
import {
  useGetAllPartinersQuery,
  useLazyGetAllPartinersQuery,
} from "../../api/partiners/partinersAPI";
import ActivateDeactivatePartiner from "./CRUD/ActivateDeactivatePartiner";
import DeletePartiner from "./CRUD/DeletePartiner";
import { setPartiners } from "../../state/features/partiners/partinersSlice";
import CreatePartiner from "./CRUD/CreatePartiner";
import UpdatePartiner from "./CRUD/UpdatePartiner";
import PartinerWidget from "../../components/widgets/PartinerWidget";
import DataTable from "react-data-table-component";
import CustomCard2 from "../../components/cards/CustomCard2";
import CountBadge from "../../components/CountBadge";

const Partiners = () => {
  // ############# Const ##############
  const RTK = useRTK();
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState("");
  const [partinerDetails, setPartinerDetails] = useState({});

  // ############# Comp State ############
  const [dialogOpen, setDialogOpen] = useState(false);

  // ############ Redux state #################
  const { partiners } = useSelector(partinersSelector);

  // ############# FUNC ###############
  const handleDialogyOpen = (type) => {
    setActionType(type);
    setDialogOpen(true);
  };
  const handleDialogyClose = () => {
    setDialogOpen(false);
  };

  // ############# RTK ##################
  const [getPartiners, { isLoading, isSuccess, data: responseData }] =
    useLazyGetAllPartinersQuery();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      // getPartiners();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setPartiners(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  const tableColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Partiner logo",
      width: "150px",
      selector: (row) => (
        <>
          <Avatar
            src={row?.image}
            variant="square"
            sx={{
              height: 60,
              width: 90,
              img: {
                objectFit: "cover",
              },
              my: 1,
              border: `1px dotted ${colors.secondary}`,
              borderRadius: 2,
            }}
          />
        </>
      ),
    },
    {
      name: "Partiner name",
      selector: (row) => <p>{row?.name}</p>,
    },
    {
      name: "Partiner website",
      selector: (row) => <p>{row?.url}</p>,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Chip
            label={row.status ? "Active" : "In-Active"}
            sx={{ mr: 1 }}
            size="small"
            color={row.status ? "success" : "warning"}
          />
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Box>
            <ActivateDeactivatePartiner status={row.status} id={row.id} />
            <IconButton
              size="small"
              sx={{
                p: 1,
                mx: 2,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
              }}
            >
              <EditTwoTone
                color="warning"
                sx={{ fontSize: 20 }}
                onClick={() => {
                  let type = "update";
                  setPartinerDetails(row);
                  handleDialogyOpen(type);
                }}
              />
            </IconButton>
            <DeletePartiner partiner={row} />
          </Box>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      {/* ########### Dialog ############## */}
      <CustomDialog
        head={
          actionType === "create" ? "Create new partiner" : "Update partiner"
        }
        openDialog={dialogOpen}
        closeDialog={handleDialogyClose}
      >
        {actionType === "create" ? (
          <CreatePartiner handleClose={handleDialogyClose} />
        ) : (
          <UpdatePartiner
            partiner={partinerDetails}
            handleClose={handleDialogyClose}
          />
        )}
      </CustomDialog>

      <PageLayout
        pageTitle={"Partiners managements"}
        isLoading={isLoading}
        action={
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<Add />}
            onClick={() => {
              let type = "create";
              handleDialogyOpen(type);
            }}
            sx={{
              py: { md: 1, xs: 1 },
              px: 2,
              fontWeight: "bold",
              fontSize: 12,
              mr: 4,
              borderRadius: `20px !important`,
            }}
          >
            Add new partiner
          </Button>
        }
      >
        {isEmpty(partiners) ? (
          <>
            <NoContent message={"No partiner added"} />
          </>
        ) : (
          <Grid container>
            <Grid item sm={12}>
              <CustomCard2
                title={"All partiners"}
                action={
                  <CountBadge total={size(partiners)} item={"Partiners"} />
                }
              >
                <DataTable
                  columns={tableColumns}
                  data={partiners}
                  rowKey={(record) => record?.id}
                  pagination={{
                    total: size(partiners),
                    current: 1,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    defaultPageSize: 5,
                    defaultCurrent: 1,
                  }}
                />
              </CustomCard2>
            </Grid>
          </Grid>
        )}
      </PageLayout>
    </>
  );
};

export default Partiners;
