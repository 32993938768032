import React, { useEffect, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Avatar, Box, Button, Chip, Grid } from "@mui/material";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { colors } from "../../assets/utils/colors";
import CreateEventPortifolio from "./CRUD/CreateEventPortifolio";
import { useDispatch, useSelector } from "react-redux";
import { eventPortifoliosSelector } from "../../state/features/selectors";
import NoContent from "../../components/NoContent";
import {
  useGetAllEventPortifoliosQuery,
  useLazyGetAllEventPortifoliosQuery,
} from "../../api/eventPortifolios/eventPortifoliosAPI";
import { setEventPortifolios } from "../../state/features/eventPortifolios/eventPortifoliosSlice";
import { isEmpty, size } from "lodash";
import useRTK from "../../hooks/useRTK";
import DataTable from "react-data-table-component";
import { Add, RemoveRedEye } from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomCard2 from "../../components/cards/CustomCard2";
import CountBadge from "../../components/CountBadge";

const EventPortifoliosPage = () => {
  // ############# CONST ################
  const dispatch = useDispatch();
  const RTK = useRTK();

  // ############# Comp State ############
  const [dialogOpen, setDialogOpen] = useState(false);

  // ############# Redux State ###########
  const { eventPortifolios } = useSelector(eventPortifoliosSelector);

  // ############ RTK ###################
  const [getEventPortifolios, { isLoading, isSuccess, data: responseData }] =
    useLazyGetAllEventPortifoliosQuery();
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      // getEventPortifolios();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  // ############# FUNC ###############
  const handleDialogyOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogyClose = () => {
    setDialogOpen(false);
  };

  // ############# RTK response ##################
  const rtkActions = (data) => {
    dispatch(setEventPortifolios(data));
  };
  RTK.useRTKFetch(isSuccess, responseData, rtkActions);

  const tableColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Tease image",
      width: "150px",
      selector: (row) => (
        <>
          <Avatar
            src={row?.tease_image}
            variant="square"
            sx={{
              height: 60,
              width: 90,
              img: {
                objectFit: "cover",
              },
              my: 1,
              border: `1px dotted ${colors.secondary}`,
              borderRadius: 2,
            }}
          />
        </>
      ),
    },
    {
      name: "Event title",
      selector: (row) => <p>{row?.event_title}</p>,
    },
    {
      name: "Client",
      selector: (row) => <p>{row?.client?.name}</p>,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Chip
            label={row.status ? "Active" : "In-Active"}
            sx={{ mr: 1 }}
            size="small"
            color={row.status ? "success" : "warning"}
          />
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`/event-portifolios/${row?.id}`}>
            <Button
              variant="outlined"
              size="small"
              color="info"
              startIcon={<RemoveRedEye />}
            >
              More details
            </Button>
          </Link>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      {/* ########### Dialog ############## */}
      <CustomDialog
        head={"Add event"}
        openDialog={dialogOpen}
        closeDialog={handleDialogyClose}
      >
        <CreateEventPortifolio handleClose={handleDialogyClose} />
      </CustomDialog>

      <PageLayout
        pageTitle={"Events managements"}
        isLoading={isLoading}
        action={
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="small"
            endIcon={<Add />}
            onClick={() => {
              let type = "create";
              handleDialogyOpen(type);
            }}
            sx={{
              py: { md: 1, xs: 1 },
              px: 2,
              fontWeight: "bold",
              fontSize: 12,
              mr: 4,
              borderRadius: `20px !important`,
            }}
          >
            Add new event
          </Button>
        }
      >
        {isEmpty(eventPortifolios) ? (
          <>
            <NoContent message={"No event added"} />
          </>
        ) : (
          <>
            <Grid container>
              <Grid item sm={12}>
                <CustomCard2
                  title={"All events"}
                  action={
                    <CountBadge
                      total={size(eventPortifolios)}
                      item={"Events"}
                    />
                  }
                >
                  <DataTable
                    columns={tableColumns}
                    data={eventPortifolios}
                    rowKey={(record) => record?.id}
                    pagination={{
                      total: size(eventPortifolios),
                      current: 1,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      pageSizeOptions: [5, 10, 25, 50, 100],
                      defaultPageSize: 5,
                      defaultCurrent: 1,
                    }}
                  />
                </CustomCard2>
              </Grid>
            </Grid>
          </>
        )}
      </PageLayout>
    </>
  );
};

export default EventPortifoliosPage;
