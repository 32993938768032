import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    clients: [],
};

// !########## SLICE #############
const clientsSlice = createSlice({
    name: 'clientsSlice',
    initialState,
    reducers: {
        setClients: (state, actions) => {
            state.clients = actions.payload;
        },
    },
});

export const { setClients } = clientsSlice.actions;
export default clientsSlice.reducer;
