import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

// ############# CARD ###############
const Card = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
  borderRadius: 10,
  overflow: "hidden",
  position: "relative",
}));

// ############# CARD HEADER ###############
const CardHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor6})`,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: 15,
  paddingRight: 10,
}));

// ############# CARD BODY ###############
const CardBody = styled(Box)(({ theme }) => ({}));

const CustomCard2 = ({
  height,
  bgColor,
  children,
  action,
  title,
  maxNoTitle,
}) => {
  return (
    <>
      <Card
        sx={{
          m: 0,
          border: `2px solid ${colors.bgColor5}`,
          borderBottom: `15px solid ${colors.bgColor6}`,
          background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
        }}
      >
        {/* ==== header ===== */}
        <CardHeader
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: "bold",
                opacity: 0.8,
              }}
              className="head-font"
            >
              {title}
            </Typography>
          </Box>
          <Box>{action}</Box>
        </CardHeader>

        {/* ==== Body ===== */}
        <CardBody
          sx={{
            height: height,
            overflow: "auto",
            position: 'relative',
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
          }}
        >
          {children}
        </CardBody>
      </Card>
    </>
  );
};

export default CustomCard2;
