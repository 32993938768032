import { Box, Button } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import Loader from 'react-js-loader';

const CustomSubmitButton = ({
    loading,
    startIcon,
    endIcon,
    onClick,
    btnColor,
    children,
}) => {
    return (
        <>
            <Box>
                <Button
                    variant="contained"
                    onClick={onClick}
                    {...(loading ? '' : '')}
                    disabled={loading ? true : false}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    color={btnColor}
                    className="app-btn"
                    fullWidth
                    sx={{
                        py: 1,
                        px: 4,
                        fontWeight: 'bold',
                        transition: `.5s all ease-in-out`,
                    }}
                >
                    {children}
                    {loading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Loader
                                type="bubble-loop"
                                bgColor={colors.secondary}
                                color={colors.secondary}
                                size={50}
                            />
                        </Box>
                    )}
                </Button>
            </Box>
        </>
    );
};

export default CustomSubmitButton;
