// => GET all event -types
const GET_ALL = '/event-types';

// => GET single package
const GET_BY_ID = '/event-types';

// => CREATE package
const CREATE = '/event-types';

// => UPDATE package
const UPDATE = '/event-types';

// => ACTIVATE package
const ACTIVATE = '/event-types/activate';

// => DEACTIVATE package
const DEACTIVATE = '/event-types/deactivate';

// => DELETE package
const DELETE = '/event-types';

export const eventTypesEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
