import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import authReducer from "../features/auth/authSlice";
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";
import gallariesReducer from "../features/gallaries/gallariesSlice";
import clientsReducer from "../features/clients/clientsSlice";
import eventTypesReducer from "../features/eventTypes/eventTypesSlice";
import partinersReducer from "../features/partiners/partinersSlice";
import eventPortifoliosReducer from "../features/eventPortifolios/eventPortifoliosSlice";

// => RTK reducers
import { authAPI } from "../../api/auth/authAPI";
import { gallariesAPI } from "../../api/gallaries/gallariesAPI";
import { clientsAPI } from "../../api/clients/clientsAPI";
import { partinersAPI } from "../../api/partiners/partinersAPI";
import { eventTypesAPI } from "../../api/eventTypes/eventTypesAPI";
import { eventPortifoliosAPI } from "../../api/eventPortifolios/eventPortifoliosAPI";

export const store = configureStore({
  reducer: {
    [authAPI.reducerPath]: authAPI.reducer,
    [gallariesAPI.reducerPath]: gallariesAPI.reducer,
    [clientsAPI.reducerPath]: clientsAPI.reducer,
    [partinersAPI.reducerPath]: partinersAPI.reducer,
    [eventTypesAPI.reducerPath]: eventTypesAPI.reducer,
    [eventPortifoliosAPI.reducerPath]: eventPortifoliosAPI.reducer,
    authReducer,
    systemConfigReducer,
    gallariesReducer,
    clientsReducer,
    eventTypesReducer,
    partinersReducer,
    eventPortifoliosReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(RTKmiddleware),
});
