import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import { Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/forms/CustomFileInput';
import { colors } from '../../../assets/utils/colors';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { useCreatePartinerMutation } from '../../../api/partiners/partinersAPI';
import { setPartiners } from '../../../state/features/partiners/partinersSlice';

const createPartinerValidationSchema = Yup.object({
    file: Yup.string().required(),
    name: Yup.string().required(),
    url: Yup.string()
        .required()
        .matches(
            /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
            'Enter correct url!',
        ),
});

const CreatePartiner = ({ handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState('');

    // ############# RTK ##################
    const [
        createPartiner,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreatePartinerMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setPartiners(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: '',
                        name: '',
                        url: '',
                    }}
                    validationSchema={createPartinerValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('image', values.file);
                        payload.append('name', values.name);
                        payload.append('url', values.url);
                        createPartiner(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} sx={{ pt: 1 }}>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item sm={12}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item sm={12}>
                                            <CustomFileInput
                                                name="file"
                                                type="file"
                                                formik={formik}
                                                accept="image/*"
                                                setFilePreview={setFilePreview}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        name="name"
                                        type={'text'}
                                        label={'Partiner'}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        name="url"
                                        type={'url'}
                                        label={'Partiner website URL'}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                    >
                                        Save partiner
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreatePartiner;
