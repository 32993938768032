import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Gallaries from "../pages/gallaries/Gallaries";
import Partiners from "../pages/partiners/Partiners";
import EventTypes from "../pages/eventTypes/EventTypes";
import Clients from "../pages/clients/Clients";
import EventPortifolioDetails from "../pages/eventsPortifolios/EventPortifolioDetails";
import EventPortifoliosPage from "../pages/eventsPortifolios/EventPortifoliosPage";

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;

// ?############## PROTECTED ELEMENTS ###########
// => Dashboard
const DashboardElement = () => (
  <AppLayout>
    <Dashboard />
  </AppLayout>
);
// => Clients
const ClientsElement = () => (
  <AppLayout>
    <Clients />
  </AppLayout>
);
// => Partiners
const PartinersElement = () => (
  <AppLayout>
    <Partiners />
  </AppLayout>
);
// => Gallaries
const GalariesElement = () => (
  <AppLayout>
    <Gallaries />
  </AppLayout>
);
// => Event types
const EventTypesElement = () => (
  <AppLayout>
    <EventTypes />
  </AppLayout>
);
// => Event poertifolios
const EventPortifoliosElement = () => (
  <AppLayout>
    <EventPortifoliosPage />
  </AppLayout>
);
// => Event types
const EventPortifolioDetailsElement = () => (
  <AppLayout>
    <EventPortifolioDetails />
  </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {/* ################## GUEST ROUTES ################## */}
        <Route element={<GuestRoutes />}>
          <Route path="/" element={<LoginElement />} />
        </Route>

        {/* ################## PROTECTED ROUTES ################## */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardElement />} />
          <Route path="/clients" element={<ClientsElement />} />
          <Route path="/partiners" element={<PartinersElement />} />
          <Route path="/gallaries" element={<GalariesElement />} />
          <Route path="/event-types" element={<EventTypesElement />} />
          <Route
            path="/event-portifolios"
            element={<EventPortifoliosElement />}
          />
          <Route
            path="/event-portifolios/:event_id"
            element={<EventPortifolioDetailsElement />}
          />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;
