import * as React from "react";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import { colors } from "../assets/utils/colors";
import { Avatar, Box, Button, List, styled, Typography } from "@mui/material";
import logo from "../assets/media/images/logo1.png";
import CustomListItem from "./CustomListItem";
import {
  AccountBoxOutlined,
  BookOnlineOutlined,
  Groups,
  Logout,
  PictureInPicture,
  ReadMore,
  Window,
} from "@mui/icons-material";
import useAuth from "../hooks/useAuth";
import { useLogoutMutation } from "../api/auth/authAPI";
import { successNotify } from "../helpers/notify";
import Loader from "react-js-loader";
import ProfileInfoWidget from "./widgets/ProfileInfoWidget";

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
  bgcolor: colors.bgColor3,
  display: "flex",
  flexDirection: "column",
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
  transition: ".3s all ease-in-out",
  "& .list-title": {
    fontWeight: "bold",
    fontSize: 12,
    padding: 5,
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
  },
  "& .list-icon": {
    fontSize: 25,
    color: colors.secondary,
  },
}));

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  border: `1px dotted ${colors.bgColor6}`,
  "& .MuiPaper-root": {
    backgroundColor: colors.bgColor1,
    border: "none !important",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
  // ############ CONST ###################
  const auth = useAuth();
  const [open] = React.useState(true);

  const { window } = "props";
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // ################# DRAWER CONTENT ################
  const navLinks = [
    {
      id: 1,
      text: "Home",
      icon: <Window className="list-icon" />,
      url: "/dashboard",
    },
    {
      id: 2,
      text: "Clients",
      icon: <Groups className="list-icon" />,
      url: "/clients",
    },
    {
      id: 3,
      text: "Partiners",
      icon: <BookOnlineOutlined className="list-icon" />,
      url: "/partiners",
    },
    {
      id: 4,
      text: "Gallaries",
      icon: <AccountBoxOutlined className="list-icon" />,
      url: "/gallaries",
    },
    {
      id: 5,
      text: "Event types",
      icon: <ReadMore className="list-icon" />,
      url: "/event-types",
    },
    {
      id: 5,
      text: "Event portifolios",
      icon: <PictureInPicture className="list-icon" />,
      url: "/event-portifolios",
    },
  ];

  // ############## RTK ######################
  const [logoutUser, { isSuccess, isLoading, isError }] = useLogoutMutation();

  React.useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (isSuccess) {
        auth.removeAuthenticatedUser();
        setTimeout(() => {
          successNotify("Logout successfully");
        }, 500);
      }
      if (isError) {
        auth.removeAuthenticatedUser();
        setTimeout(() => {
          successNotify("Logout successfully");
        }, 500);
        // errorNotify('Fail to logout. Try again!');
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [isSuccess, isError, auth]);
  const drawer = (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        background: `linear-gradient(180deg, #000, #000)`,
      }}
    >
      <Box
        sx={{
          // height: 200,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottomRightRadius: 45,
          borderBottomLeftRadius: 25,
          background: `#000`,
          position: "relative",
        }}
      >
        <Avatar
          src={logo}
          sx={{ height: 150, width: 150, position: "absolute", opacity: 0.4 }}
        />
        <ProfileInfoWidget />
      </Box>
      <Box sx={{ width: "100%" }}>
        <DrawerContent>
          <Box>
            <Box>
              <List sx={{ p: 0 }}>
                {navLinks?.map((item) => (
                  <CustomListItem
                    key={item.id}
                    text={item.text}
                    icon={item.icon}
                    url={item.url}
                  />
                ))}
              </List>
            </Box>
          </Box>
        </DrawerContent>
      </Box>
      <Box
        sx={{
          height: 60,
          width: "100%",
        }}
      >
        <Button
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            borderRadius: `0 !important`,
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor6})`,
          }}
          disabled={isLoading ? true : false}
          fullWidth
          color="error"
          onClick={() => {
            logoutUser();
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Logout</Typography>
          <Logout sx={{ fontSize: 35 }} />
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
              }}
            >
              <Loader
                type="bubble-loop"
                bgColor={colors.secondary}
                color={colors.secondary}
                size={50}
              />
            </Box>
          )}
        </Button>
      </Box>
    </Box>
  );
  return (
    <>
      {/* ##################### mobile ################# */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "80%", // Change the width of drawer in mobile
          },
        }}
      >
        <Toolbar />
        {drawer}
      </Drawer>

      {/* ##################### desktop ################ */}
      <DrawerDesktop
        variant="permanent"
        open={open}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        {drawer}
      </DrawerDesktop>
    </>
  );
};

export default SideBar;
