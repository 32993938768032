// => GET all partiners
const GET_ALL = '/partiners';

// => GET single package
const GET_BY_ID = '/partiners';

// => CREATE package
const CREATE = '/partiners';

// => UPDATE package
const UPDATE = '/partiners';

// => ACTIVATE package
const ACTIVATE = '/partiners/activate';

// => DEACTIVATE package
const DEACTIVATE = '/partiners/deactivate';

// => DELETE package
const DELETE = '/partiners';

export const partinersEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
