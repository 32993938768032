import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import CustomTextField from '../../../components/forms/CustomTextField';
import CustomMultlineTextField from '../../../components/forms/CustomMultlineTextField';
import CustomSubmitButton from '../../../components/forms/CustomSubmitButton';
import { Image, Save } from '@mui/icons-material';
import CustomFileInput from '../../../components/forms/CustomFileInput';
import { colors } from '../../../assets/utils/colors';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { useUpdateGallaryMutation } from '../../../api/gallaries/gallariesAPI';
import { setGallaries } from '../../../state/features/gallaries/gallariesSlice';

const updateGallaryValidationSchema = Yup.object({
    file: Yup.string(),
    event: Yup.string().required(),
    year: Yup.number().required(),
    description: Yup.string().nullable(),
});

const UpdateGallary = ({ handleClose, gallary }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState(gallary.image);

    // ############# RTK ##################
    const [
        updateGallary,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useUpdateGallaryMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setGallaries(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: '',
                        event: gallary.event,
                        year: gallary.year,
                        description: gallary.description,
                    }}
                    validationSchema={updateGallaryValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append('gallary_id', gallary.id);
                        payload.append('image', values.file);
                        payload.append('event', values.event);
                        payload.append('year', values.year);
                        payload.append('description', values.description);
                        updateGallary(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} sx={{ pt: 1 }}>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item sm={12}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                            color:
                                                                colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item sm={12}>
                                            <CustomFileInput
                                                name="file"
                                                type="file"
                                                formik={formik}
                                                accept="image/*"
                                                setFilePreview={setFilePreview}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={8} xs={12}>
                                    <CustomTextField
                                        name="event"
                                        type={'text'}
                                        label={'event'}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CustomTextField
                                        name="year"
                                        type={'text'}
                                        label={'year'}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomMultlineTextField
                                        name="description"
                                        type={'text'}
                                        label={'Descriptions'}
                                        rows={4}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                    >
                                        UPdate gallary
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdateGallary;
