// => GET all clients
const GET_ALL = '/clients';

// => GET single package
const GET_BY_ID = '/clients';

// => CREATE package
const CREATE = '/clients';

// => UPDATE package
const UPDATE = '/clients';

// => ACTIVATE package
const ACTIVATE = '/clients/activate';

// => DEACTIVATE package
const DEACTIVATE = '/clients/deactivate';

// => DELETE package
const DELETE = '/clients';

export const clientsEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
